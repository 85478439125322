const options = [
  { to: "https://resume.sagarsanjeev.com/", text: "Old Design" },
  { to: "/", text: "Home" },
  { to: "/about/", text: "About" },
  { to: "/experience/", text: "Experience" },
  { to: "/projects/", text: "Projects" },
  { to: "/education/", text: "Education" },
  { to: "/others/", text: "Other Info" },
  { to: "/homepage/", text: "One Page Design" },
];

export default options;
