import React from "react";
import { Animated } from "../../components";
import { Typography, Fade } from "@mui/material";
import { Row, Col } from "reactstrap";

function Others() {
  return (
    <>
      <Fade in timeout={1000}>
        <Typography
          variant="h6"
          color="textPrimary"
          style={{ fontWeight: "bold" }}>
          <Animated>Awards & Achivements</Animated>
        </Typography>
      </Fade>
      <ul>
        <li>
          <b>Global Tech Innovation Contest</b>, Top 5 Ideas, Accenture
        </li>
        <li>
          <b>Financial Services Hackathon</b>, 2nd Runner Up, Accenture
        </li>
        <li>
          <b>Project Innovation Contest</b>, 2nd Runner Up, ICDCIT
        </li>
        <li>
          <b>Best Volunteer</b>, National Service Scheme
        </li>
      </ul>
      <Fade in timeout={1000}>
        <Typography
          variant="h6"
          color="textPrimary"
          style={{ fontWeight: "bold" }}>
          <Animated>Patents</Animated>
        </Typography>
      </Fade>
      <ul>
        <li>
          <b>
            Simulation of One or More Pipeline Jobs in a Software Deployment
            Pipeline
          </b>
        </li>
        <Row>
          <Col md={5}>
            <ul>
              <li>
                <b>Issuer and No. :</b> US 18/077, 761
              </li>
              <li>
                <b>Filling Date :</b> December 29, 2022
              </li>
            </ul>
          </Col>
        </Row>
        <li>
          <b>
            Extracting Actionable Items From Documents And Assigning The
            Actionable Items to Responsible Parties
          </b>
        </li>
        <div style={{ height: "5px" }}></div>
        <Row>
          <Col md={5}>
            <ul>
              <li>
                <b>Issuer and No. :</b> US US11314938B2
              </li>
              <li>
                <b>Filling Date :</b> July 29, 2019
              </li>
              <li>
                <b>Grant Date :</b> April 26, 2022
              </li>
            </ul>
          </Col>
          <Col>
            <ul>
              <li>
                <b>Issuer and No. :</b> AU 2020207818A1
              </li>
              <li>
                <b>Filling Date :</b> July 22, 2020
              </li>
              <li>
                <b>Grant Date :</b> November 11, 2021
              </li>
            </ul>
          </Col>
        </Row>
      </ul>
      {/* <Fade in timeout={1000}>
        <Typography
          variant="h6"
          color="textPrimary"
          style={{ fontWeight: "bold" }}>
          <Animated>Education</Animated>
        </Typography>
      </Fade>
      <ul>
        <li>
          <b>KIIT University</b>, B.Tech(CSE), Bhubaneswar
        </li>
        <li>
          <b>Krishna Public School</b>, Senior Secondary School, Patna
        </li>
        <li>
          <b>Delhi Public School</b>, Secondary School, Patna
        </li>
      </ul> */}
      <Fade in timeout={1000}>
        <Typography
          variant="h6"
          color="textPrimary"
          style={{ fontWeight: "bold" }}>
          <Animated>Certification</Animated>
        </Typography>
      </Fade>
      <ul>
        <li>Neo4J Professional Certification, Neo4j, 2020</li>
        <li>IBM Professional Data Science, Coursera, 2020</li>
        <li>Azure AZ-900 Certification, Microsoft, 2020</li>
        <li>Cloud Computing Certification, Rackspace, 2016</li>
      </ul>
    </>
  );
}
export default Others;
