import React from "react";
import { LayoutHeader } from "../components";
import { Education } from "../containers";

const EducationPage = () => (
  <LayoutHeader>
    <Education />
  </LayoutHeader>
);

export default EducationPage;
