import React from "react";
import { LayoutHeader } from "../components";
import { Others } from "../containers";

const OtherPage = () => (
  <LayoutHeader>
    <Others />
  </LayoutHeader>
);

export default OtherPage;
