/* eslint-disable no-unused-vars */
import React, { useState, createRef } from "react";
import ColorThief from "colorthief";
import { withStyles } from "@mui/styles";
import styles from "./style";
import { Row, Col } from "reactstrap";
import { Card, CardContent, Typography, Avatar } from "@mui/material";

function ExperinceCard({ classes, cardInfo }) {
  const [colorArrays, setColorArrays] = useState([]);
  const imgRef = createRef();
  function getColorArrays() {
    const colorThief = new ColorThief();
    setColorArrays(colorThief.getColor(imgRef.current));
  }

  function rgb(values) {
    return values.length === 0
      ? "rgb(4, 124, 188)"
      : "rgb(" + values.join(", ") + ")";
  }

  return (
    <>
      <Card style={{ margin: "20px 20px" }}>
        <CardContent
          style={{
            background: rgb(colorArrays),
            height: "75px",
            padding: "10px",
          }}
        >
          <Row>
            <Col md={3}>
              <Avatar
                style={{
                  height: "55px",
                  width: "55px",
                  background: cardInfo.logo ? "white" : "rgb(4, 124, 188)",
                }}
              >
                {cardInfo && cardInfo.logo && (
                  <img
                    ref={imgRef}
                    src={cardInfo.companylogo}
                    alt="avatar"
                    style={{
                      height: "50px",
                      width: "50px",
                      objectFit: "contain",
                      padding: cardInfo.type === "idea" ? "5px" : "0px",
                    }}
                    onLoad={() => getColorArrays()}
                  />
                )}
              </Avatar>
            </Col>
            <Col>
              <Row>
                <Typography
                  color="textPrimary"
                  style={{
                    fontSize: "0.88rem",
                    fontFamily: "Roboto",
                    color: "white",
                    fontWeight: 700,
                    lineHeight: 1.1,
                    letterSpacing: "0rem",
                  }}
                >
                  {cardInfo.company}
                </Typography>
              </Row>
              <Row>
                <Typography
                  color="textPrimary"
                  style={{
                    fontSize: "0.88rem",
                    fontFamily: "Roboto",
                    color: "white",
                    fontWeight: 500,
                    lineHeight: 1.1,
                    letterSpacing: "0rem",
                  }}
                >
                  {cardInfo.role}
                </Typography>
              </Row>
              <Row>
                <Typography
                  color="textPrimary"
                  style={{
                    fontSize: "0.80rem",
                    fontFamily: "Roboto",
                    color: "white",
                    fontWeight: 500,
                    lineHeight: 1.1,
                    letterSpacing: "0rem",
                  }}
                >
                  {cardInfo.date}
                </Typography>
              </Row>
            </Col>
          </Row>
        </CardContent>
      </Card>
    </>
  );
}
export default withStyles(styles)(ExperinceCard);
