import React from "react";
import { Animated, EducationCard } from "../../components";
import { Typography, Fade } from "@mui/material";
import { Row, Col } from "reactstrap";
// import dell from "../../images/dell-logo.svg";
import kiit from "../../images/kiit-logo.svg";
import dps from "../../images/dps-logo.png";

function Education() {
  const experienceData = [
    {
      role: "B.Tech in Computer Science",
      company: "KIIT University",
      companylogo: kiit,
      logo: true,
      date: "2014 - 2018",
      link: "https://sagarsanjeev.com/#experience",
      location: "Bhubaneswar, India",
    },
    {
      role: "Senior Secondary School",
      company: "Krishna Public School",
      logo: false,
      date: "2012 - 2014",
      link: "https://www.kpspatna.com/",
      location: "Patna, India",
    },
    {
      role: "Secondary School",
      company: "Delhi Public School, Patna",
      companylogo: dps,
      logo: true,
      date: "2012",
      link: "https://www.dpspatna.com/",
      location: "Patna, India",
    },
  ];

  return (
    <>
      <Fade in timeout={1000}>
        <Typography
          variant="h4"
          color="textPrimary"
          style={{ fontWeight: "bold" }}
        >
          <Animated>Education</Animated>
        </Typography>
      </Fade>
      <br />
      <Row className="p-0">
        {experienceData.map((data, i) => {
          return (
            <Col className="p-0" key={i}>
              <EducationCard key={i} isDark={false} cardInfo={data} />
            </Col>
          );
        })}
      </Row>
      <br />
    </>
  );
}
export default Education;
